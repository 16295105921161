import { UserPage } from "../../../js/Base/UserPage";

export class OzApiProvider extends UserPage {

    _WebApi(apiType, apiMethod, param, errorState) {
      return this.WebApi(apiType, apiMethod, param,
        (res) => {
          // console.log(res.data.data); 
          return res;
        },
        (err) => {
          if (errorState) {
            err = {
              msg: errorState(err.response.data.code),
              code: err.response.data.code
            }
          }
          throw err;
        });
    }
  
    findAllAccount(page, page_size, search) {
      const data = {
        page: page,
        page_size: page_size,
        search: search
      }
      return this._WebApi('acct', "FindAllAccount", data);
    }
  
    findAccountByNo(acctNo) {
      return this._WebApi("acct", "FindAccountByNo", { acct_no: acctNo });
    }
  
    findAccountByAccountID(acct_id) {
      return this._WebApi("acct", "FindAccountByID", { acct_id });
    }
  
    editAccount(account) {
      return this._WebApi("acct", "EditAccount", { acct: account });
    }
  
    editCompany(company) {
      return this._WebApi("comp", "EditCompany", { company: company });
    }
  
    findAllCompany(page = 1, pageSize = 10, search = null) {
      const data = {
        "page": page,
        "page_size": pageSize,
        "search": search
      }
  
      return this._WebApi("comp", "FindAllCompany", data);
    }
  
  
    findCompanysByAccountNo(accountNo) {
      return this._WebApi("comp", "FindCompanysByAcct", { acct_no: accountNo });
    }
  
    findCompanysByNoList(companyNoList) {
      return this._WebApi("comp", "FindCompanysByNoList", { no_list: companyNoList });
    }
  
    createCompanyConfiguration = (companyCfg) => {
      const data = { company_cfg: companyCfg };
      return this._WebApi("comp", "CreateCompanyConfiguration", data);
    };
  
    editCompanyConfiguration = (companyCfg) => {
      console.log(companyCfg);
      const data = { company_cfg: companyCfg };
      return this._WebApi("comp", "EditCompanyConfiguration", data);
    };
  
    findManageableCompanies(accountNo) {
      return this._WebApi("comp", "FindManageableCompanies", { acct_no: accountNo });
    }
  
    findCompanyMembersByCompanyNo(companyNo, page, pageSize, searchKeyword) {
      const data = {
        "company_no": companyNo,
        "page": page,
        "page_size": pageSize,
        "search": searchKeyword
      }
      return this._WebApi("comp", "FindCompanyMembersByCompanyNo", data);
    }
  
    findCompanyMembersNotInProject(companyNo, projectNo, page, pageSize, searchKeyword) {
      const data = {
        "company_no": companyNo,
        "project_no": projectNo,
        "page": page,
        "page_size": pageSize,
        "search": searchKeyword
      }
      return this._WebApi("comp", "FindCompanyMembersNotInProject", data);
    }
  
    editCompanyMember(companyMember) {
      return this._WebApi("comp", "EditCompanyMember", { company_member: companyMember });
    }
  
    editCompanyMemberRole(company_member_no, is_set_sub_sys_owner) {
      const errorState = (code) => {
        switch (code) {
          case '1':
            return "不可刪除自己的管理權限";
          default:
            return null;
        }
      }
  
      const data = {
        "company_member_no": company_member_no,
        "is_set_sub_sys_owner": is_set_sub_sys_owner
      }
  
      return this._WebApi("comp", "EditCompanyMemberRole", data, errorState)
  
    }
  
    findAllCompGroupsByCompNo(companyNo, page, pageSize, search = null) {
      const data = {
        com_no: companyNo,
        page: page,
        page_size: pageSize,
        search: search
      }
      return this._WebApi("comp", "FindAllCompGroupsByCompNo", data);
    }

  
    findSubCompanyGroupsByParentNo(companyNo = null, parentNo = null) {
      let data = {};
      if (companyNo === null) {
        data = { parent_no: parentNo };
      } else {
        data = { company_no: companyNo };
      }
      return this._WebApi("comp", "FindSubCompanyGroupsByParentNo", data);
    }
  
    findCompanyGroupMembersByCompanyGroupNo(compGroupNo, page, pageSize = 10, search = null) {
      const data = {
        company_group_no: compGroupNo,
        page: page,
        page_size: pageSize,
        search: search
      }
      return this._WebApi("comp", "FindCompanyGroupMembersByCompanyGroupNo", data);
    }

  
    findCompanyMembersByNoList(noList) {
      return this._WebApi("comp", "FindCompanyMembersByNoList", { no_list: noList });
    }
  
    findAccountsByNoList(noList) {
      return this._WebApi("acct", "FindAccountsByNoList", { acct_no_list: noList });
    }
  
  

  

  
    findCompanyMembersByAcctNo(accountNo) {
      return this._WebApi("comp", "FindCompanyMembersByAcctNo", { acct_no: accountNo });
    }
  
 
  
    findCompanyWorkingWeekDayByCompNo(companyNo) {
      return this._WebApi("comp", "FindCompanyWorkingWeekDayByCompNo", { comp_no: companyNo });
    }
  
    editCompanyWorkingWeekDay(companyWorkingWeekDay) {
      return this._WebApi("comp", "EditCompanyWorkingWeekDay", { company_working_week_day: companyWorkingWeekDay });
    }
  
    createCompanyCalender(companyCompanyCalender) {
      return this._WebApi("comp", "CreateCompanyCalender", { company_calender: companyCompanyCalender });
    }
  
    findCompanyCalendersByCompNo(companyNo, page, pageSize) {
      const data = {
        comp_no: companyNo,
        page: page,
        page_size: pageSize
      }
      return this._WebApi("comp", "FindCompanyCalendersByCompNo", data);
    }
  
    deleteCompanyCalenderByNo(companyCompanyCalenderNo) {
      return this._WebApi("comp", "DeleteCompanyCalenderByNo", { no: companyCompanyCalenderNo });
    }
  
    createProject(project) {
      return this._WebApi("proj", "CreateProject", { project: project });
    }
  
    findProjectsByCompanyNo(companyNo, page, pageSize) {
      const data = {
        company_no: companyNo,
        page: page,
        page_size: pageSize
      }
      return this._WebApi("proj", "FindProjectsByCompanyNo", data);
    }
  
    findManageableProjectByCompanyNo(companyNo) {
      const data = {
        company_no: companyNo
      }
      return this._WebApi("proj", "FindManageableProjectByCompanyNo", data)
    }
  
    editProject(project) {
      return this._WebApi("proj", "EditProject", { project: project })
    }
  
    findProjectMembersByProjectNo(projectNo, page, pageSize, search_keyword = null) {
      const data = {
        project_no: projectNo,
        page: page,
        page_size: pageSize,
        search: search_keyword,
      }
  
      return this._WebApi("proj", "FindProjectMembersByProjectNo", data)
    }
  
    createProjectMember(projectNo, companyMemberNo, projectRoleNoList, joinDt) {
      const data = {
        project_member: {
          project_no: projectNo,
          company_member_no: companyMemberNo,
          status: 1,
          join_dt: joinDt
        },
        project_role_no_list: projectRoleNoList
      }
  
      return this._WebApi("proj", "CreateProjectMember", data)
    }
  
    editProjectMember(projectMember) {
      return this._WebApi("proj", "EditProjectMember", { project_member: projectMember })
    }
  
    findProjectRolesByProjectNo(projectNo) {
      return this._WebApi("proj", "FindProjectRolesByProjectNo", { project_no: projectNo })
    }
  
    createProjectRole(projectNo, name, desc, authorityCode) {
      const data = {
        project_role: {
          project_no: projectNo,
          name: name,
          desc: desc,
          authority_code: authorityCode
        }
      }
  
      return this._WebApi("proj", "CreateProjectRole", data)
    }
  
    editProjectRole(projectRole) {
      return this._WebApi("proj", "EditProjectRole", { project_role: projectRole })
    }
  
    findProjectMemberRolesByProjectMemberNo(projectMemberNo) {
      return this._WebApi("proj", "FindProjectMemberRolesByProjectMemberNo", { project_member_no: projectMemberNo })
    }
  
    findAllProjectAuthTypes() {
      return this._WebApi("proj", "FindAllProjectAuthTypes", {})
    }
  
    createProjectMemberRole(projectMemberNo, roleNo) {
      const data = {
        project_member_no: projectMemberNo,
        role_no: roleNo
      }
      return this._WebApi("proj", "CreateProjectMemberRole", data)
    }
  
    deleteProjectMemberRole(projectMemberNo, roleNo) {
      const data = {
        project_member_no: projectMemberNo,
        role_no: roleNo
      }
      return this._WebApi("proj", "DeleteProjectMemberRole", data)
    }
  
  }
  