import React from 'react'
import moment from 'moment'

import OzViewModel from '../Domain/Common/struct'
import { isNullOrUndefined } from '../Utils/utils'
import i18n from "i18next";

const week_day_code = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export const weekdayStrName = week_day_code.map(day => i18n.t(`CloudSecurity.SystemSetting.ReportSubscribe.${day}`));

export const weekOfMonthOption = [
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.first"), value: 0 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.second"), value: 1 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.third"), value: 2 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.forth"), value: 3 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.last"), value: -1 },
]

export const monthOption = [
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.jan"), value: 1 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.feb"), value: 2 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.mar"), value: 3 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.apr"), value: 4 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.may"), value: 5 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.jun"), value: 6 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.jul"), value: 7 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.aug"), value: 8 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.sep"), value: 9 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.oct"), value: 10 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.nov"), value: 11 },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.dec"), value: 12 },
]

export const quarterlyOption = [
  { displayName: '1、4、7、10', value: 1 },
  { displayName: '2、5、8、11', value: 2 },
  { displayName: '3、6、9、12', value: 3 },
]

export const ScheduleTimeTypeEnum = Object.freeze({
  Once: 'Once',
  Monthly: 'Monthly',
  Week_Of_Month: 'Week_Of_Month',
  Quarterly: 'Quarterly',
  Weekday: 'Weekday',
  Daily: 'Daily',
  Multi_Month: 'Multi_Month',
})

export const ScheduleTimeSelectOptions = [
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.monthly-cycle") , value: ScheduleTimeTypeEnum.Monthly },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.monthly-cycle-week"), value: ScheduleTimeTypeEnum.Week_Of_Month },
  // { displayName: "月週期(特定月)", value: ScheduleTimeTypeEnum.Multi_Month },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.quarter-cycle"), value: ScheduleTimeTypeEnum.Quarterly },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.weekly-interval"), value: ScheduleTimeTypeEnum.Weekday },
  { displayName: i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.per-day"), value: ScheduleTimeTypeEnum.Daily },
  // { displayName: "固定日期", value: ScheduleTimeTypeEnum.Once },
]

export class OzSchedule extends OzViewModel {
  constructor(
    no = null,
    schedule_times = [],
    desc = '',
    status = null,
    start_time = null,
    end_time = null,
    project_no = null,
    priority = null,
    create_time = null,
    create_acct_no = null,
    timezone = null
  ) {
    super()
    this.no = no
    this.desc = desc
    this.status = status
    this.start_time = start_time ? start_time : new Date()
    this.end_time = end_time ? end_time : new Date(9999, 11, 31)
    this.project_no = project_no
    this.schedule_times = schedule_times
    this.priority = priority
    this.create_time = create_time
    this.create_acct_no = create_acct_no
    this.timezone = timezone
  }

  fromDict(dict) {
    super.fromDict(dict)
    if (!isNullOrUndefined(dict)) {
      this.schedule_times = (dict.schedule_times || []).map(st => new OzScheduleTime().fromDict(st))
    }

    return this
  }

  isDisable() {
    return this.status === 0
  }

  isExpired() {
    const end_time = moment.utc(this.end_time).local()
    const now = moment.utc().local()
    return !end_time.isAfter(now)
  }

  showScheduleTimePeriod() {
    const start_time_str = moment.utc(this.start_time).local().format('YYYY-MM-DD')
    const end_time_str = moment.utc(this.end_time).local().format('YYYY-MM-DD')
    return start_time_str + '~' + end_time_str + (this.isExpired() === true ? '(已過期)' : '')
  }

  showStatusStr() {
    return this.isDisable() ? i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.disable") : i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.enable")
  }
}

export class OzScheduleTime extends OzViewModel {
  constructor(
    no = null,
    type = null,
    value = new OzScheduleTimeValue(),
    skip = null,
    is_ignore_saturday = false,
    is_ignore_sunday = false,
    is_ignore_holidays = false
  ) {
    super()
    this.no = no
    this.type = type
    this.value = value
    this.skip = skip
    this.is_ignore_holidays = is_ignore_holidays
    this.is_ignore_saturday = is_ignore_saturday
    this.is_ignore_sunday = is_ignore_sunday
  }

  fromDict(dict) {
    super.fromDict(dict)
    if (!isNullOrUndefined(dict)) {
      this.value = new OzScheduleTimeValue().fromDict(dict.value)
    }
    return this
  }

  checkError() {
    let res = true

    switch (this.type) {
      case ScheduleTimeTypeEnum.Once:
        res = this.value.year && this.value.month && this.value.day
        break
      case ScheduleTimeTypeEnum.Monthly:
        res = this.value.day !== null
        break
      case ScheduleTimeTypeEnum.Week_Of_Month:
        res = this.value.week_of_month && this.value.weekdays.size > 0
        break
      case ScheduleTimeTypeEnum.Quarterly:
        res = this.value.month && this.value.day
        break
      case ScheduleTimeTypeEnum.Weekday:
        res = this.value.weekdays.size
        // console.log(this)
        break
      case ScheduleTimeTypeEnum.Daily:
        break
      case ScheduleTimeTypeEnum.Multi_Month:
        res = this.value.months.size > 0 && this.value.day
        break
      default:
        break
    }
    return res
  }

  showValueStr() {
    // 顯示
    let res = ''
    switch (this.type) {
      case ScheduleTimeTypeEnum.Once:
        res = this.value.year + i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.years") + this.value.month + '月' + this.value.day
        break
      case ScheduleTimeTypeEnum.Monthly:
        res = `${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.every")}  ${(this.skip === null ? 1 : this.skip)} ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.months")}， ${this.value.day} ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.days")}`
        break
      case ScheduleTimeTypeEnum.Week_Of_Month:
        res =
          i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.per-month") +
          weekOfMonthOption.find(wm => wm.value === parseInt(this.value.week_of_month)).displayName +
          '星期 的' +
          this.value.weekdays.map(day => weekdayStrName[day]).join('、')
        break
      case ScheduleTimeTypeEnum.Quarterly:
        res =
          `${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.every")}
          ${(this.skip === null ? 1 : this.skip)}
          ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.quarter")}
          ，第
          ${quarterlyOption.find(option => option.value === parseInt(this.value.month))?.displayName}
          ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.month")}
          ，
          第
          ${this.value.day}
          ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.days")}`
        break
      case ScheduleTimeTypeEnum.Weekday:
        res =` ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.every")} ${(this.skip === null ? 1 : this.skip)} ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.week")}，  ${this.value.weekdays.map(day => weekdayStrName[day]).join('、')}`
        break
      case ScheduleTimeTypeEnum.Daily:
        res = i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.once-a-day")
        break
      case ScheduleTimeTypeEnum.Multi_Month:
        res =
          `${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.every")} ${(this.skip === null ? 1 : this.skip)} ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.years")}
          ，${ this.value.months.map(month => monthOption.find(option => option.value === month)?.displayName).join('、 ') }
          '，第 ${this.value.day} ${i18n.t("CloudSecurity.SystemSetting.ReportSubscribe.days")}`
        break
      default:
        break
    }
    return res
  }

  showTypeStr() {
    const option = ScheduleTimeSelectOptions.find(option => ScheduleTimeTypeEnum[option.value] === ScheduleTimeTypeEnum[this.type])
    return option.displayName
  }
}

export class OzScheduleTimeValue extends OzViewModel {
  constructor(
    no = null,
    year = null,
    month = null,
    day = null,
    weekdays = new Set(),
    week_of_month = null,
    hour = null,
    minute = null,
    months = new Set()
  ) {
    super()
    this.no = no
    this.year = year
    this.month = month
    this.day = day
    this.weekdays = weekdays
    this.week_of_month = week_of_month
    this.hour = hour
    this.minute = minute
    this.months = months
  }

  convCrontab() {
    let crontab = ''
    crontab += (this.year ? this.year : '*') + ' '
    crontab += (this.month ? this.month : '*') + ' '
    crontab += (this.day ? this.day : '*') + ' '
    crontab += (this.hour ? this.hour : '*') + ' '
    crontab += (this.minute ? this.minute : '*') + ' '
    crontab += (this.weekdays.size !== 0 ? '[' + this.weekdays.join(',') + ']' : '*') + ' '
    crontab += (this.week_of_month ? this.week_of_month : '*') + ' '
    crontab += (this.months.size !== 0 ? '[' + this.months.join(',') + ']' : '*') + ' '
    return crontab
  }

  fromDict(dict) {
    super.fromDict(dict)
    this.weekdays = dict.weekdays ? new Set(dict.weekdays) : new Set()
    this.months = dict.months ? new Set(dict.months) : new Set()

    return this
  }
}
