import React from "react";
import { CallWebApi, CallWebApi2 } from "./CallWebApi";
import { GetStatusName } from "../../pages/admin/CloudSecurity/Flowza/Ticket/utils";

// import { connect } from 'react-redux'
export class UserPage extends React.Component {
  edc_Incident_category_no = 25;

  GetWebApiConfig = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${this.GetUser().token}`,
      },
    };
    return config;
  };

  WebApi = async (ServiceName, MethodName, payload, success_action = null, error_action = null) => {
    return await CallWebApi(
      ServiceName,
      MethodName,
      payload,
      this.GetWebApiConfig(),
      await success_action,
      this.Error_Action(error_action)
    );
  };

  WebApi2 = async (ServiceName, MethodName, payload) => {
    let data = await CallWebApi2(ServiceName, MethodName, payload, this.GetWebApiConfig())
      .then((v) => {
        return v.data.data;
      })
      .catch((err) => {
        return err.response ? err.response.status : 500;
      });

    return data;
  };

  Error_Action = (error_action) => (err) => {
    console.log(err.response);

    //發生401錯誤時把使用者登出
    if (err.response === 401) this.SignOut();
    if (error_action) error_action(err);
  };

  GetStorageValue = (key) => {
    return localStorage.getItem(key);
  };

  GetUser = () => {
    let user = {
      token: String,
      id: String,
      lan: String,
      name: String,
      no: Number,
      timezone: String,
    };
    user.token = this.GetStorageValue("token");
    user.id = this.GetStorageValue("id");
    user.lan = this.GetStorageValue("lan");
    user.name = this.GetStorageValue("name");
    user.no = parseInt(this.GetStorageValue("no"), 10);
    user.timezone = this.GetStorageValue("timezone");

    return user;
  };

  SignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("lan");
    localStorage.removeItem("name");
    localStorage.removeItem("no");
    localStorage.removeItem("timezone");
    localStorage.removeItem("company_no");
    localStorage.removeItem("project_no");
    localStorage.removeItem("company_name");
    localStorage.removeItem("expiration");
    localStorage.removeItem("company_list");
    localStorage.removeItem("project_name");
    localStorage.removeItem("project_list");
    localStorage.removeItem("category_no");

    localStorage.removeItem("openSideBar");
    localStorage.removeItem("firstMenu");
    localStorage.removeItem("selectedFirstMenu");
    localStorage.removeItem("secondMenu");
    localStorage.removeItem("thirdMenu");
    localStorage.removeItem("thirdMenuList");
    localStorage.removeItem("userNav");

    localStorage.removeItem("autoscan");
    localStorage.removeItem("recent_page");
    localStorage.removeItem("provider");
    localStorage.removeItem("selectedRegion");
    localStorage.removeItem("currencyRates");
    localStorage.removeItem("selectedRows");
    this.GoToLogin();
  };

  GoToLogin = () => {
    // this.props.history.push('/login') //有時不會轉頁
    // window.location = '/login' //在iphone不會轉頁
    document.location.assign(document.location.origin + "/login");
    // return <Redirect to="/login" />
  };

  constructor(props) {
    super(props);
    if (!localStorage.getItem("token")) this.SignOut();
  }

  ChangeDefaultStatusName = (name) => {
    return GetStatusName(name);
  };

  GetMyPersonalization = async (acct_no = null) => {
    let personalization = this.GetStorageValue("Personalization");
    if (!personalization) {
      if (!acct_no) acct_no = this.GetUser().no;
      const acct_data = await this.WebApi("acct", "FindAccountDataByAcctNo", { acct_no })
        .then((v) => {
          return v.data.data;
        })
        .catch((err) => {
          return err.response ? err.response.status : 500;
        });
      personalization = acct_data.personalization;
      localStorage.setItem("Personalization", acct_data.personalization);
    }

    return JSON.parse(personalization);
  };

  GetPersonalizationItem = (personalization, key, defaultValue = null) => {
    try {
      defaultValue = personalization[key];
    } catch (error) {}

    return defaultValue;
  };

  //取得我在某公司下所有的公司組織
  GetMyCompanyGroupByCompanyNo = async (company_no, acct_no = null) => {
    if (!acct_no) acct_no = this.GetUser().no;
    let company_groups = await this.WebApi("comp", "FindCompanyGroupByAcctNo", { acct_no, company_no })
      .then((v) => {
        return v.data.data;
      })
      .catch((err) => {
        return err.response ? err.response.status : 500;
      });
    return company_groups;
  };

  //取得我是管理者的公司
  GetMyManagerCompanyObj = async () => {
    let companys = await this.WebApi("comp", "FindManageableCompanies", { acct_no: this.GetUser().no })
      .then((v) => {
        return v.data.data;
      })
      .catch((err) => {
        return err.response ? err.response.status : 500;
      });
    return companys;
  };

  //取得我所有的高業流程權限
  GetMyProjectRoles = async () => {
    let project_roles = await this.WebApi("proj", "FindProjectRolesByAcctNo", { acct_no: this.GetUser().no })
      .then((v) => {
        return v.data.data;
      })
      .catch((err) => {
        return err.response ? err.response.status : 500;
      });
    return project_roles;
  };
}
