const initState = {
  bind_ticket: {},
  bind_ticket_no_page: {},
  errorcode: null,
}

const ticketBindTicketReducer = (state = initState, action) => {

  switch (action.type) {
    case 'BindTicket_Error':
      // console.log(action)
      return {
        ...state,
        // tickets: null,
        errorcode: action.payload ? action.payload : 500
      }

    case 'BindSubTicketByMainTicketNo_SUCCESS':
      let bind_ticket_by_main_ticket = state.bind_ticket[action.main_ticket_no]
      
      if (!bind_ticket_by_main_ticket) {
        bind_ticket_by_main_ticket = {}
      }
      bind_ticket_by_main_ticket[action.page] = action.payload
      bind_ticket_by_main_ticket["total"] = action.payload.total
      state.bind_ticket[action.main_ticket_no] = bind_ticket_by_main_ticket

      return {
        ...state,
        bind_ticket: {
          ...state.bind_ticket
        },
        errorcode: null
      }
    case "GetBindSubTicketWithNoPage_SUCCESS":
      let bind_ticket_no_page = {};
      bind_ticket_no_page["items"] = action.payload.items;
      bind_ticket_no_page["total"] = action.payload.items.length;
      return {
        ...state,
        bind_ticket_no_page: bind_ticket_no_page,
        errorcode: null,
      };
    case 'DelBindSubTicketByMainTicketNo':
      state.bind_ticket[action.main_ticket_no] = null
      return {
        ...state,
        bind_ticket: {
          ...state.bind_ticket
        },
        errorcode: null
      }
    default:
      return state;
  }
}

export default ticketBindTicketReducer