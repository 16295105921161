import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import AppMenuItems from './AppMenuItems';
import { Row, Container } from 'react-bootstrap';
import menus from '../../configs/portal/portal-menus';
import AppMenuDropdown from './AppMenuDropdown';

const AppMenu = (props) => {
    const { tl, tlns, isLogin, setIsLogin } = props;
    const [selectMenu, setSelectMenu] = useState(undefined);
    const [open, setOpen] = React.useState(false);

    const onNoSelectHandler = () => {
        setSelectMenu(undefined);
        setOpen(false);
    };

    return (
        <div className="AppMenu">
            <nav className="desktop-nav">
                <ul className="menus">
                    {menus.map((menu, index) => {
                        return (
                            <AppMenuItems
                                {...props}
                                items={menu}
                                key={'menu' + index}
                                selectMenu={selectMenu}
                                setSelectMenu={setSelectMenu}
                                open={open}
                                setOpen={setOpen}
                            />
                        );
                    })}
                </ul>
                <ul className="menus" style={{ paddingTop: '15px' }}>
                    <li>
                        <Link to={'/login'} style={{ textDecoration: 'none' }} onClick={onNoSelectHandler}>
                            <span className="text-nav-item" style={{ color: '#5F5F5F' }}>
                                {tl(tlns, 'console')}
                            </span>
                        </Link>
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                        <button className="btn-primary btn-xs" type="submit" onClick={onNoSelectHandler}>
                            <Link to={{ pathname: '/login', search: `id=register` }} style={{ textDecoration: 'none', color: '#5F5F5F' }}>
                                {tl(tlns, 'register')}
                            </Link>
                        </button>
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                        <Link
                            to={{ pathname: '/login', search: `id=login` }}
                            style={{ textDecoration: 'none' }}
                            onClick={onNoSelectHandler}
                        >
                            <span className="text-nav-item" style={{ color: '#5F5F5F' }}>
                                {tl(tlns, 'login')}
                            </span>
                        </Link>
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </ul>
            </nav>
            <div className="menu-dropdown" style={{ display: open ? 'block' : 'None' }}>
                <Container fluid>
                    <Row
                        className="menuitem-dropdown vertical-flex"
                        onMouseLeave={onNoSelectHandler}
                        style={{
                            height:
                                menus.filter((m) => m.title === selectMenu && m.submenu.length > 3).length > 0
                                    ? '300px'
                                    : 'auto',
                        }}
                    >
                        {selectMenu !== '' &&
                            menus.map((menu, index) => {
                                if (menu.title === selectMenu) {
                                    return (
                                        <AppMenuDropdown
                                            {...props}
                                            submenu={menu.submenu}
                                            key={'menu' + index}
                                            setOpen={setOpen}
                                        />
                                    );
                                } else {
                                    return '';
                                }
                            })}
                    </Row>
                </Container>
            </div>
            <div></div>
        </div>
    );
};

export default AppMenu;
