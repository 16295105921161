import React from 'react';
import { tokenLoader } from '../../js/Base/auth.js';


const CloudPortal = React.lazy(() => import('../../layout/CloudPortal'));
const Page404 = React.lazy(() => import('../../pages/Page404'));
const Page500 = React.lazy(() => import('../../pages/Page500'));

const User = React.lazy(() => import('../../pages/admin/Users/User'));
const LineNotify = React.lazy(() => import('../../pages/admin/Users/Notify'));

const AllService = React.lazy(() => import('../../pages/admin/Overview/AllService'));
const Cloudgoda = React.lazy(() => import('../../pages/admin/Overview/Cloudgoda'));

const SimplePriceComparison = React.lazy(() =>
    import('../../pages/portal/CloudServiceProviderQuery/SimplePriceComparison/SimplePriceComparison')
);
const LatencyTesting = React.lazy(() => import('../../pages/portal/CloudServiceProviderQuery/LatencyTesting'));

const VmSpecDetails = React.lazy(() =>
    import('../../pages/portal/CloudServiceProviderQuery/VmSpecDetails/VmSpecDetails')
);

const DesignDeploy = React.lazy(() => import('../../pages/portal/DesignDeploy/DesignDeploy'));

const ConsoleMain = React.lazy(() => import('../../pages/admin/Overview/ConsoleMain'));
const ClassificationList = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ResourceAssetConfiguration/ClassificationList')
);
const ResourceAssetConfiguration = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ResourceAssetConfiguration/ResourceAssetConfiguration')
);
const AzurePerformance = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ResourceDashboard/AzurePerformance')
);
const PerformanceContent = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ResourceDashboard/PerformanceContent')
);

const SecurityPosture = React.lazy(() => import('../../pages/admin/CloudSecurity/SecurityPosture/SecurityPosture'));
const SecurityScoreRecommendations = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/SecurityPosture/SecurityScoreRecommendations')
);
const RegulatoryCompliance = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/RegulatoryCompliance/RegulatoryCompliance')
);
const AzureCostDashboard = React.lazy(() =>
    import('../../pages/admin/SmartManagement/AzureCost/AzureCostDashboard/AzureCostDashboardPage')
);
const ClassificationCost = React.lazy(() =>
    import('../../pages/admin/SmartManagement/AzureCost/ClassificationCost/ClassificationCostPage')
);
const BestCost = React.lazy(() => import('../../pages/admin/SmartManagement/AzureCost/BestCost/BestCost'));
const BestReliability = React.lazy(() => import('../../pages/admin/SmartManagement/BestSmart/BestReliability'));
const BestMaintenance = React.lazy(() => import('../../pages/admin/SmartManagement/BestSmart/BestMaintenance'));
const BestPerformance = React.lazy(() => import('../../pages/admin/SmartManagement/BestSmart/BestPerformance'));
const BestSecurity = React.lazy(() => import('../../pages/admin/CloudSecurity/BestSecurity/BestSecurity'));

// Flowza
const MyToDoList = React.lazy(() => import('../../pages/admin/CloudSecurity/Flowza/Ticket/MyToDoList/myToDoList'));
const CreateWorkTicket = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/Flowza/Ticket/WorkTicket/createticketpage')
);
const Ticket = React.lazy(() => import('../../pages/admin/CloudSecurity/Flowza/Ticket/ticket'));
const SOCDashboard = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/Flowza/Report/DashboardDisplay/DashboardDisplay')
);
const SOCReport = React.lazy(() => import('../../pages/admin/CloudSecurity/Flowza/Report/ReportSoc'));
const ReportDownload = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/Flowza/Report/ServicePortal/ReportDownload')
);
const UnclosedListByUser = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/Flowza/Ticket/UnclosedList/UnclosedListByUser')
);
const UnclosedListByIP = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/Flowza/Ticket/UnclosedList/UnclosedListByIP')
);

const ProjectSearchTicket = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/Flowza/Ticket/SearchTicket/projectSearchTicket')
);
const JobOverview = React.lazy(() => import('../../pages/admin/CloudBackUp/JobOverview/JobOverviewPage'));
const BackUpInfrastructure = React.lazy(() =>
    import('../../pages/admin/CloudBackUp/BackUpInfrastructure/BackUpInfrastructurePage')
);
const BackupTraffic = React.lazy(() => import('../../pages/admin/CloudBackUp/BackUpTraffic/BackUpTraffic'));
const CompanyMmgt = React.lazy(() => import('../../pages/admin/Company/CompanyManagement.js'));
const CompanyBaseInfo = React.lazy(() => import('../../pages/admin/Company/CompanyBaseInfo/CompanyBaseInfoPage.js'));
const CompanyMemberManagement = React.lazy(() => import('../../pages/admin/Company/CompanyMemberManagement/CompanyMemberManagementPage.js'));
const CompanyOrgManagement  = React.lazy(() => import('../../pages/admin/Company/CompOrgManagement/CompanyOrgManagementPage.js'));
const ProcessManagement = React.lazy(() => import('../../pages/admin/Company/ProcessManagement/ProcessManagementPage.js'));
const CompanyWorkingDay = React.lazy(() => import('../../pages/admin/Company/CompanyWorkingDay/CompanyWorkingDay.js'));
const CompanyWizard = React.lazy(() => import('../../pages/admin/Company/CompanyWizard/CompanyWizard.js'));

const ProjectMmgt = React.lazy(() => import('../../pages/admin/Company/ProjectManagement.js'));
const ReportSubscribeList = React.lazy(() => import('../../pages/admin/Report/ReportSubscribeListPage.js'));
const ReportSubscribe = React.lazy(() => import('../../pages/admin/Report/CreateReportSubscribe.js'));
const UpdateReportSubscribe = React.lazy(() => import('../../pages/admin/Report/UpdateReportSubscribe.js'));

const ActiveServiceIssue = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ServiceHealthCheck/ActiveServiceIssue')
);
const ActivePlannedMaintenance = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ServiceHealthCheck/ActivePlannedMaintenance')
);
const AzureStatus = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ServiceHealthCheck/AzureStatus')
);
const ActionGroup = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ServiceHealthCheck/ActionGroup')
);
const HealthAlert = React.lazy(() =>
    import('../../pages/admin/OperationalExcellence/Azure/ServiceHealthCheck/HealthAlert')
);
const EmployeeSchedulePage = React.lazy(() =>
    import('../../pages/admin/CloudSecurity/Flowza/EmployeeSchedule/EmployeeSchedulePage')
);
const authRoutes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        element: <CloudPortal />,
        id: 'root',
        loader: tokenLoader,
        children: [
            {
                path: '/',
                name: 'home',
                element: <ConsoleMain />,
            },
            {
                path: '/cloudgoda',
                name: 'cloudgoda',
                element: <Cloudgoda />,
            },
            {
                path: '/overview/allservice',
                name: 'allservice',
                element: <AllService />,
            },
            //控制台
            {
                path: '/console',
                name: 'console',
                element: <ConsoleMain />,
            },
            // 雲端服務供應商查詢
            {
                path: '/pricecomparison',
                exact: true,
                name: '',
                element: <SimplePriceComparison />,
            },
            // 網路延遲性檢測
            {
                path: '/latency-testing',
                exact: true,
                name: '',
                element: <LatencyTesting />,
            },
            {
                path: '/design-deploy',
                exact: true,
                name: '',
                element: <DesignDeploy />,
            },
            // 細部調整
            {
                path: '/vm-spec-details',
                exact: true,
                name: '',
                element: <VmSpecDetails />,
            },
            //資源資產組態-分類清單
            {
                path: '/classification-list',
                name: 'classification-list',
                element: <ClassificationList />,
            }, //監控儀錶板-AZURE效能
            {
                path: '/azure-performance',
                name: 'azure-performance',
                element: <AzurePerformance />,
            }, //監控儀錶板-AZURE效能內容
            {
                path: '/performance-content',
                name: 'performance-content',
                element: <PerformanceContent />,
            },
            //資源資產組態
            {
                path: '/resource-asset-configuration',
                name: 'resource-asset-configuration',
                element: <ResourceAssetConfiguration />,
            },
            // 安全性建議
            {
                path: '/cloud/security',
                exact: true,
                name: '',
                element: <BestSecurity />,
            },
            // Flowza
            {
                path: '/my-todo-list',
                exact: true,
                name: '',
                element: <MyToDoList />,
            },
            {
                path: '/incident',
                exact: true,
                name: '',
                element: <CreateWorkTicket />,
            },
            {
                path: '/ticket/:no',
                exact: true,
                name: '',
                element: <Ticket />,
            },
            {
                path: '/soc-dashboard',
                exact: true,
                name: '',
                element: <SOCDashboard />,
            },
            {
                path: '/soc-report',
                exact: true,
                name: '',
                element: <SOCReport />,
            },
            {
                path: '/report-download',
                exact: true,
                name: '',
                element: <ReportDownload />,
            },
            {
                path: '/search-history-ticket',
                exact: true,
                name: '',
                element: <ProjectSearchTicket />,
            },
            // 班表管理
            {
                path: '/employee_schedule',
                exact: true,
                name: '',
                element: <EmployeeSchedulePage />,
            },
            {
                path: '/pending-ticket-user-ranking',
                exact: true,
                name: '',
                element: <UnclosedListByUser />,
            },
            {
                path: '/pending-ticket-ip-ranking',
                exact: true,
                name: '',
                element: <UnclosedListByIP />,
            },
            // 安全性態勢
            {
                path: '/cloud/security/securityPosture',
                exact: true,
                name: '',
                element: <SecurityPosture />,
            },
            // 安全分數建議
            {
                path: '/cloud/security/securityScoreRecommendations',
                exact: true,
                name: '',
                element: <SecurityScoreRecommendations />,
            },
            // 法規合規性
            {
                path: '/cloud/regulatoryCompliance',
                exact: true,
                name: '',
                element: <RegulatoryCompliance />,
            },
            // 智能管理
            {
                path: '/azure-cost-dashboard',
                exact: true,
                name: '',
                element: <AzureCostDashboard />,
            },
            {
                path: '/classificationCost',
                exact: true,
                name: '',
                element: <ClassificationCost />,
            },
            {
                path: '/best/cost',
                exact: true,
                name: '',
                element: <BestCost />,
            },
            {
                path: '/best/reliabilityRecommendations',
                exact: true,
                name: '',
                element: <BestReliability />,
            },
            {
                path: '/best/operationalRecommendations',
                exact: true,
                name: '',
                element: <BestMaintenance />,
            },
            {
                path: '/best/performanceRecommendations',
                exact: true,
                name: '',
                element: <BestPerformance />,
            },
            // 營運優化
            // 作⽤中的服務問題事件
            {
                path: '/best/operationalExcellence/activeServiceIssue',
                exact: true,
                name: '',
                element: <ActiveServiceIssue />,
            },
            // 作⽤中的計劃性維護事件
            {
                path: '/best/operationalExcellence/activePlannedMaintenance',
                exact: true,
                name: '',
                element: <ActivePlannedMaintenance />,
            },
            // Azure 狀態
            {
                path: '/best/operationalExcellence/azureStatus',
                exact: true,
                name: '',
                element: <AzureStatus />,
            },
            // 動作群組
            {
                path: '/best/operationalExcellence/actionGroup',
                exact: true,
                name: '',
                element: <ActionGroup />,
            },
            // 健康狀態警⽰
            {
                path: '/best/operationalExcellence/healthAlert',
                exact: true,
                name: '',
                element: <HealthAlert />,
            },
            // 備份備援
            {
                path: '/job-overview',
                exact: true,
                name: '',
                element: <JobOverview />,
            },
            {
                path: '/backup-infrastructure',
                exact: true,
                name: '',
                element: <BackUpInfrastructure />,
            },
            {
                path: '/backup-traffic',
                exact: true,
                name: '',
                element: <BackupTraffic />,
            },
            //系統設定
            //公司管理
            {
                path: '/company-management',
                exact: true,
                name: '',
                element: <CompanyMmgt />,
            },
            //基本資料
            {
                path: '/company/company-base-info',
                exact: true,
                name: '',
                element: <CompanyBaseInfo />,
            },
           //帳號管理
            {
                path: '/company/company-member-management',
                exact: true,
                name: '',
                element: <CompanyMemberManagement />,
            },
             //組織管理
             {
                path: '/company/company-org-management',
                exact: true,
                name: '',
                element: <CompanyOrgManagement />,
            },
             //公司管理->流程管理
             {
                path: '/company/process-management',
                exact: true,
                name: '',
                element: <ProcessManagement />,
            },
            //工作和休假日管理
            {
                path: '/company/company-working-day-management',
                exact: true,
                name: '',
                element: <CompanyWorkingDay />,
            },
            //設定精靈
            { 
                path: '/company/firstWizard',
                exact: true,
                name: '',
                element: <CompanyWizard />,

            },
            //流程管理
            {
                path: '/project/process-management',
                exact: true,
                name: '',
                element: <ProjectMmgt />,
            },
            //SOC報表訂閱列表
            {
                path: '/project/mgmt/report_subscribe/:no/list',
                exact: true,
                name: '',
                element: <ReportSubscribeList />,
            },
            //新增
            {
                path: '/project/mgmt/report_subscribe/:project_no/list/new',
                exact: true,
                name: '',
                element: <ReportSubscribe />,
            },
            //更新
            {
                path: '/project/mgmt/report_subscribe/:project_no/list/edit/:no',
                exact: true,
                name: '',
                element: <UpdateReportSubscribe />,
            },
            {
                path: '/page500',
                exact: true,
                name: 'Page500',
                element: <Page500 />,
            },
            {
                path: '/*',
                exact: true,
                name: 'Page404',
                element: <Page404 />,
            },
            // User
            {
                path: '/user',
                exact: true,
                name: '',
                element: <User />,
            },
            // line Notify Link Account
            {
                path: '/linenotify',
                exact: true,
                name: '',
                element: <LineNotify />,
            },
        ],
    },
];

export default authRoutes;
